.main-navigation ul {
	// display: none;
	right: -350px;
	list-style: none;
	margin: 0;
	padding-left: 0;
	// transition: top 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
	// transition: all 0.1s ease;
}

.main-navigation a {
	display: block;
	padding: 0.8em 0;
	position: relative;
	text-decoration: none;
}

.main-navigation ul {
	list-style: none;
	margin: 0;
}

.main-navigation ul ul {
	display: none;
	margin-left: 0.8em;
}

.main-navigation ul .toggled-on {
	display: block;
}

.main-navigation li {
	// border-top: 1px solid #eaeaea;
	// border-top: 1px solid rgba(51, 51, 51, 0.1);
	// position: relative;
}

.main-navigation .current-menu-item > a,
.main-navigation .current-menu-ancestor > a {
	font-weight: 700;
}

.main-navigation .nav-menu > ul > li:first-child,
.main-navigation .nav-menu > li:first-child {
	border-top: 0;
}

.main-navigation .menu-item-has-children > a {
	padding-right: 48px;
}

.main-navigation .menu-item-description {
	color: #707070;
	color: rgba(51, 51, 51, 0.7);
	font-family: "Noto Sans", sans-serif;
	font-size: 12px;
	font-size: 1.2rem;
	font-weight: 400;
	line-height: 1.5;
	margin-top: 0.5em;
}

.no-js .main-navigation ul ul {
	display: block;
}

.dropdown-toggle {
	background-color: transparent;
	border: 0;
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
	content: "";
	height: 42px;
	padding: 0;
	position: absolute;
	text-transform: lowercase; /* Stop screen readers to read the text as capital letters */
	top: 3px;
	right: 0;
	width: 42px;
}

.dropdown-toggle:after {
	color: #333;
	content: "\f431";
	font-size: 24px;
	line-height: 42px;
	position: relative;
	top: 0;
	left: 1px;
	width: 42px;
}

.dropdown-toggle:hover,
.dropdown-toggle:focus {
	background-color: #eaeaea;
	background-color: rgba(51, 51, 51, 0.1);
}

.dropdown-toggle:focus {
	outline: 1px solid #c1c1c1;
	outline: 1px solid rgba(51, 51, 51, 0.3);
}

.dropdown-toggle.toggle-on:after {
	content: "\f432";
}

/* Small menu. */
.menu-toggle,
.main-navigation.toggled ul {
	// display: block;
	right: -30px;
}

@media screen and (min-width: 961px) {
	.menu-toggle {
		display: none;
	}
	.main-navigation ul.nav-menu {
		height: 95px;
		display: flex;
		flex-wrap: no-wrap;


		li {
			padding: 0 10px;
			width: 33.3333%;
			height: 100%;
			flex: 1 1;
			text-transform: uppercase;
			text-align: center;
			display: flex;
			// align-content: center;
			align-items: center;
			justify-content: center;
			transition: 0.2s;


			&:hover {
				background: $red;

				a {
					color: #fff;
				}
			}

			a {
				color: $red;
			}
		}
		
		.current-menu-item {
			background: $red;

			a {
				color: #fff;
			}
		}
	}
	
}

@media screen and (max-width: 960px) {
	.main-navigation {
		position: relative;

		ul.nav-menu {
			position: absolute;
			width: 250px;
			// right: 0;
			// right: 300px; 

			top: 61px;
			// padding: 30px;
			background: $red;
			height: 100vh;
			transition: all 0.25s ease;

			li {

				&:hover {
					background: #fff;

					a {
						color: $red;
					}
				}
				a {
					padding: 30px;
					color: #fff;
					font-size: 1.285714286rem;
				}
			}
		}
	}
}

@include breakpoint(small) {
	.main-navigation {
		ul.nav-menu {
			top: 43px;
		}
	}
}

.site-main .comment-navigation,
.site-main .posts-navigation,
.site-main .post-navigation {
	margin: 0 0 1.5em;
	overflow: hidden;
}

.comment-navigation .nav-previous,
.posts-navigation .nav-previous,
.post-navigation .nav-previous {
	float: left;
	width: 50%;
}

.comment-navigation .nav-next,
.posts-navigation .nav-next,
.post-navigation .nav-next {
	float: right;
	text-align: right;
	width: 50%;
}

/*--------------------------------------------------------------
## Other Navigation
--------------------------------------------------------------*/
.comment-navigation,
.post-navigation,
.paging-navigation {
	padding: 2em 0;
	font-family: "Fira Sans", sans-serif;
	text-align: left;
	overflow: hidden;
}

@media screen and (min-width: 50em) {
	.comment-navigation,
	.post-navigation,
.paging-navigation {
		padding: 2em 3em;
	}
}

.nav-links {
	max-width: 45em;
	margin: 0 auto;
	padding: 0 .4em;
}

.comment-navigation a,
.post-navigation a,
.paging-navigation a {
	display: block;
	width: 100%;
	text-decoration: none;
	padding: 1em;
	border: 1px solid #c3c3c3;
}

.comment-navigation a:hover,
.comment-navigation a:focus,
.post-navigation a:hover,
.post-navigation a:focus,
.paging-navigation a:hover,
.paging-navigation a:focus {
	border-color: #000;
}

.post-navigation .post-title {
	font-size: 1.2em;
	font-weight: 700;
}

.post-navigation a:hover .post-title,
.post-navigation a:focus .post-title {
	text-decoration: underline;
}

.nav-previous {
	padding-bottom: 1em;
}

@media screen and (min-width: 30em) {

	.nav-links {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		padding: 0;
	}

	.comment-navigation .nav-previous,
	.post-navigation .nav-previous {
		display: flex;
		align-items: stretch;
		float: left;
		width: 48%;
	}

	.nav-previous {
		padding-bottom: 0;
	}

	.comment-navigation .nav-next,
	.post-navigation .nav-next {
		display: flex;
		align-items: stretch;
		float: right;
		text-align: right;
		width: 48%;
	}
}

.post-navigation .meta-nav {
	display: block;
}

@media screen and (min-width: 30em) {
	.post-navigation .meta-nav {
		padding-bottom: 1em;
	}
}

.paging-navigation {
	margin-top: -2em;
	text-align: center;
}

.paging-navigation ul {
	display: inline-block;
	padding: 0;
	list-style-type: none;

}

.paging-navigation li {
	display: inline-block;
}

.paging-navigation li a {
	padding: .3em 1em;
	margin: .4em .2em;
}

.paging-navigation .current {
	padding: .8em 1.1em;
	font-weight: 700;
}