.site-header {
	width: 100vw;
	height: 95px;
	position: fixed;
	background: #fff;
	border-bottom: 3px solid $red;
	z-index: 9999;

	.inner-header {
		max-width: 1200px;
		height: 100%;
		padding: 0 30px;
		margin: 0 auto;
		display: flex;
		// flex-wrap: wrap;
		align-items: center;

		.site-branding {
			padding: 10px 0;
			margin-right: 20px;
			flex: 2;

			a {
				display: inline-block;

				img {
					width: 250px;
					// height: 80px;
				}
			}
		}

		.main-navigation {
			flex: 4;
		}
	}
}

@include breakpoint(small) {
	.site-header {
		height: auto;

		.inner-header {
			.site-branding {
				a {
					img {
						width: 180px;
					}
				}
			}
		}
	}
}