.menu-toggle {
	border: none;
	background: none;
	float: right;
	right: 0;
	width: 40px;
	height: 30px;
	padding: 0;
	position: relative;
	// margin: 50px auto;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .25s ease-in-out;
	-moz-transition: .25s ease-in-out;
	-o-transition: .25s ease-in-out;
	transition: .25s ease-in-out;
	cursor: pointer;

	&:focus {
		outline: none;
	}
}

.menu-toggle span {
  display: block;
  position: absolute;
  height: 6px;
  width: 100%;
  background: $red;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}


.menu-toggle span:nth-child(1) {
  top: 0px;
}

.menu-toggle span:nth-child(2),.menu-toggle span:nth-child(3) {
  top: 13px;
}

.menu-toggle span:nth-child(4) {
  top: 26px;
}

.menu-toggle.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.menu-toggle.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.menu-toggle.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.menu-toggle.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

@include breakpoint(small) {
  .menu-toggle {
    width: 30px;
    height: 22.5px;
  }

  .menu-toggle span {
    height: 3px;
  }


  .menu-toggle span:nth-child(1) {
    top: 0px;
  }

  .menu-toggle span:nth-child(2),.menu-toggle span:nth-child(3) {
    top: 9px;
  }

  .menu-toggle span:nth-child(4) {
    top: 19px;
  }
}

.testimonial-entry {
  button {
    background: $red;
  }

  .slick-prev,
  .slick-next
  {


      position: absolute;
      top: auto;

      display: block;

      width: 50px;
      height: 50px;
  }

  .slick-prev:before,
  .slick-next:before
  {
      font-family: 'fontawesome';
      font-size: 20px;
      line-height: 1;

      opacity: .75;
      color: #fff;

      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      transition: 0.2s;
  }

  .slick-prev,
  .slick-next {
    &:hover:before {
      opacity: 1;
    }
  }

  .slick-prev
  {
      left: auto;
      right: 3px;
      bottom: -65px;
      background: $red;
  }
  [dir='rtl'] .slick-prev
  {
      right: 3px;
      left: auto;
  }
  .slick-prev:before
  {
      content: '\f053';
  }
  [dir='rtl'] .slick-prev:before
  {
      content: '\f054';
  }

  .slick-next
  {
      right: -47px;
      bottom:-65px;
      background: darken($red, 10%);
  }
  [dir='rtl'] .slick-next
  {
      right: auto;
      left: auto;
  }
  .slick-next:before
  {
      content: '\f054';
  }
  [dir='rtl'] .slick-next:before
  {
      content: '\f053';
  }
}

@include breakpoint(medium-down) {
  .testimonial-entry {
    .slick-prev
    {
        right: 10px;
    }

    .slick-next
    {
        right: -40px;
    }
    
  }
}

@include breakpoint(small) {
  .testimonial-entry {
    .slick-prev
    {
        right: 20px;
        bottom: -55px;
    }

    .slick-next
    {
        right: -30px;
        bottom: -55px;
    }
    
  }
}

.more-info {
  display: table;
  text-decoration: none;
  margin-top: 20px;
  // background: $red;
  font-size: 1.142857143rem;
  color: #fff;
  border: 4px solid $red;
  padding: 15px;
  transition: all 0.2s;

  &:hover {
    // border: none;
    background: $red;
  }
}