/*
====================================
GLOBAL STYLES
====================================
*/

// html,
// body {
// 	overflow-x: hidden;
// }

.container {
	max-width: 1200px;
	padding: 0 30px;
	margin: 0 auto;
}

/*
====================================
HOMEPAGE
====================================
*/

.page-id-30 {
	#page {
		background: $red;
		display: flex;
		flex-direction: column;
		height: 100vh;

		header {
			// position: static;
		}
		footer.site-footer {
			flex: 2;
		}
	}
}

// .home{
// 	height: 100%;
// }

#post-30 {
	padding: 95px 0 0 0;
	overflow: auto;
}

// .slick-slider {
// 	height: calc(100vh - 95px);
// }

.hero-home {
	width: 100%;
	// height: 90%;
	// height: auto;
	// height: 100vh;
	// height: calc(100vh - 185px);
	overflow-x: hidden;
	// height: calc(100% - 92px);
	// padding-top: 92px;
	// height: calc(100vh - 95px);
	// height: 95vh;

	.slick-slide {
		width: 100%;
		// height: 90%;
		// max-height: calc(100vh - 95px);
	}

	.slick-slide img {
		width: 100%;
		// height: 90%;
		// max-height: calc(100vh - 95px);
	}

		.hero-image {
			width: auto;
			// height: 100%!important;
			// height: 100vh;
			// height: calc(100vh - 185px);
			// height: calc(100% - 95px);

			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			position: relative;

			img {
				// width: 100%;
				// min-height: 100%;
			}
	
			&:before {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+60,0.8+100 */
				background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0) 60%, rgba(0,0,0,0.9) 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 60%,rgba(0,0,0,0.9) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 60%,rgba(0,0,0,0.9) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 ); /* IE6-9 */
	
				// /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+50,000000+50&0.3+0,0+50 */
				// background: -moz-linear-gradient(top,  rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 30%); /* FF3.6-15 */
				// background: -webkit-linear-gradient(top,  rgba(0,0,0,0.3) 0%,rgba(0,0,0,0) 30%); /* Chrome10-25,Safari5.1-6 */
				// background: linear-gradient(to bottom,  rgba(0,0,0,0.3) 0%,rgba(0,0,0,0) 30%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4d000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */




			}

			.container {
				position: absolute;
				top:0;
				right: 0;
				bottom: 25%;
				left: 0;
				margin:0 auto;
				width: 100vw;
				// height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				// align-items: center;
				transition: all 0.2s ease;

				hr {
					color: $red; 
					background: $red; 
					border: 0; 
					width: 100px;
					height: 8px; 
					margin: 10px 0 20px;
					z-index: 2;
					// display: none;
				}

				.hero-text {
					max-width: 700px;
					margin-bottom: 50px;
					z-index: 2;
					// text-align: center;
					// position: absolute;
					// bottom: 200px;

					h1 {
						color: #fff;
						text-shadow: 2px 2px 5px rgba(0, 0, 0, 1);
						}				
					}
			}
			.scrolled {
				bottom: 0;
			}
		}
}

.scrolled {
	bottom: 0;
}


@media all and (min-width: 1280px) and (max-width: 2350px) {
	.hero-home {
		.hero-image {
			.container {
				bottom: 24%;
			}
			.scrolled {
				bottom: 0;
			}

		}
	}
}


@media all and (max-width: 1279px) {
	.hero-home {
		.hero-image {
			.container {
				bottom: 0;
			}
			.hero-text {
				margin-bottom: 50px;
			}
		}
	}
}

@include breakpoint(medium) {
	.hero-home {
		.hero-image {
			.container {

				.hero-text {
					margin-bottom: 50px;
				}
			}
		}
	}
}

@include breakpoint(small) {
	#post-30 {
		padding-top: 66px;
		overflow: auto;
	}
	.hero-home {
		.hero-image {
			// height: calc(100vh - 66px);

			.container {
				hr {
					display: none;
				}
				.hero-text {
					margin-bottom: 50px; 

					// h1 {
					// 	font-size: 2.142857143rem;
					// }
				}
			}
		}
	}
}

@include breakpoint(medium-down) {

	.page-id-30 {
		// height: auto;
		.hero-home {
			.hero-image {
				// height: calc(100vh - 66px);

				.container {
					.hero-text {

						h1 {
							font-size: 2.142857143rem;
						}
					}
				}
			}
		}
		footer.site-footer {
			flex: 2;

			.container {


				.site-info {
					display: block;

				}
			}
		}
	}
}

/*
====================================
ABOUT PAGE
====================================
*/

.about-content {
	width: 100vw;
	min-height: 100vh;
	padding: 150px 0 50px 0;

	.container {
		display: flex;
		flex-wrap: wrap;
		height: 100%;

		.overview {
			flex: 2;
			margin-right: 75px;
			height: auto;

			.group-photo {
				width: 100%;
				min-height: 250px;
				margin-top: 30px;
				margin-bottom: 15px;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
			}
		}

		.testimonials {
			max-width: 421.67px;
			flex: 1;
			background: #F5F5F5;
			padding: 40px;
			position: relative;

			.small-title {
				margin-top: 0;
				color: $red;
			}

			h4 {
				margin-bottom: 30px;
			}

			.testimonial-entry {
				max-width: 321.66px;

				.client-logo {
					width: 150px;
					height: 100px;

					img {
						height: 100%;
					}
				}

				.slide {
					margin-bottom: 50px;
				}

				.small {
					color: $font;
					margin: 30px 0;
				}

				.testimonial-desc {
					font-style: italic;
				}

				.client-name {
					margin: 0;
					font-weight: 700;
				}

				.testimonial-details {
					display: flex;

					p {
						margin-bottom: 0;
					}

					.title,
					.company {
						margin: 0;
					}
				}
			}
		}
	}
}

@include breakpoint(medium-down) {
	.about-content {
		height: auto;

		.container {

			.overview {
				flex: 1;
				margin-right: 0;
				margin-bottom: 40px;
			}

			.testimonials {
				flex: 1;
				height: auto;
				max-width: 100%;

				.testimonial-entry {
					max-width: 100%;
				}
			}

			.overview,
			.testimonials {
				flex-basis: 100%;
			}
		}
	}
}

@include breakpoint(small) {
	.about-content {

		.container {

			.testimonials {
				padding: 30px;
			}
		}
	}
}

/*
====================================
CONTACT PAGE
====================================
*/

.contact-page {
	width: 100vw;
	height: auto;

	.container {
		height: 100%;
		padding-top: 150px;

		.contact-desc {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 70px;

			h5 {
				border-bottom: 1px solid $font;
				display: table;
				padding-bottom: 10px;
				margin-bottom: 15px;
			}

			p {
				line-height: 1.5;

				span {
					font-weight: 700;
				}
			}

			a {
				text-decoration: none;
				color: $font;
				transition: 0.2s;

				&:hover {
					color: $red;
					text-decoration: underline;
				}
			}
		
			.map {
				flex: 3;
				margin-right: 50px;
			}

			.details {
				flex: 1;

				.contact-address {
					p {
						margin: 0;
					}
				}
			}

			.leasing {
				margin-top: 40px;
				p {
					margin: 0;
				}

				.lease-email {
					margin-bottom: 15px;
				}
			}
		}

	}
}


@media (min-width: 601px) and (max-width: 768px) {
	.contact-page {

		.container {

			.contact-desc {

			
				.map {
					flex: 2;

					.wpgmza_map {
						height: 440px !important;
					}
				}

				.details {
					flex: 1;
				}
			}

		}
	}
}

@media (min-width: 320px) and (max-width: 600px) {
	.contact-page {

		.container {

			.contact-desc {

			
				.map {
					flex: 1;

					.wpgmza_map {
						height: 440px !important;
					}
				}

				.details {
					flex: 1;
				}

				.map,
				.details {
					flex-basis: 100%;
				}

				.map {
					margin-right: 0;
					margin-bottom: 40px;

					.wpgmza_map {
						height: 250px !important;
					}
				}
			}

		}
	}
}

/*
====================================
TENANT PAGE
====================================
*/
.tenant {
	width: 100%;
	height: 100vh;
	padding: 150px 0 80px;
	display: flex;
	align-items: center;

	.login-window {
		// position: relative;
		// background: mistyrose;
		// padding-bottom: 56.25%;
		// padding-top: 35px;
		// height: auto;
		// overflow: scroll;
		overflow-y: scroll;
		overflow-x: scroll;
		-webkit-overflow-scrolling: touch;
		width: 100%;
		height: 665px;

		iframe {
			// position: absolute;
			// top:0;
			// left: 0;
			width: 960px;
			height: 665px;
			margin: 0 auto;
			border: none;
		}
	}

	// iframe {
	// 	// max-width: 100% !important;
	// 	// height: 665px;
	// 	margin: 0 auto;
	// 	// border: none;
	// }
}

@media (max-width: 1280px) {
	.tenant {
		height: auto;
	}
}


/*
====================================
DEVELOPMENT PAGE
====================================
*/
.dev-page {
	width: 100vw;
	height: auto;

	#largeImgPanel {
	               text-align: center;
	               display: none;
	               position: fixed;
	               z-index: 100;
	               top: 0; left: 0; width: 100%; height: 100%;
	               background-color: rgba(100,100,100, 0.5);
	           }

	.container {
		height: auto;
		padding-top: 150px;
		padding-bottom: 80px;

		.dev-container {
			// display: flex;
			// flex-wrap: wrap;

			.dev-header {
				height: auto;
				margin-bottom: 20px;

				p {
					font-size: 1.142857143rem;
					line-height: 1.5;
					margin: 5px 0 0 0;
				}
			}

			.dev {
				width: 100%;
				height: auto;
				// margin: 0 15px;
				// flex: 1 1 calc(33.333% - 30px);
				// padding: 50px;
				// border: 10px solid transparent;
				transition: all 0.3s;
				// padding-bottom: 20px;
				margin: 50px 0;
				padding-bottom: 50px;
				border-bottom: 1px solid $red;

				// &:nth-of-type(2n) {
				// 	margin-right: 0;
				// }

				// .short-desc {
				// 	margin: 0;
				// }

				&:last-of-type {
					border-bottom: none;
				}

				.dev-content {	
					display: flex;
					flex-wrap: wrap;
					// background: mistyrose;
					align-items: center;


					.link,
					.plan-graphic,
					.dev-desc {
						margin: 0 20px;
						flex-basis: calc(33.333% - 40px);
					}

					a.link {
						// display: block;
						// // margin: 20px 0 30px 0;
						// margin-left: 0;
						// flex: 2;

						margin-left: 0;
						flex: auto;
						margin: 0 10px;
						flex-basis: calc(50% - 20px);
						margin-left: 0;

						.dev-map {
							width: 100%;
							height: 350px;
							// width: 100%;
							// height: 400px;
							background-size: cover;
							background-position: center;
							background-repeat: no-repeat;
							overflow: hidden;
							position: relative;

							.overlay {
								position: absolute;
								background: black;
								background: rgba($red,0.8);
								color: white;
								width: 100%;
								height: 100%;
								left: -100%;
								opacity: 0;
								transition: all 0.3s ease;
								display: flex;
								align-items: center;
								
								p {
									color: white;
									margin: 0 auto;

									&:hover {
										text-decoration: underline;
									}
								}
							}

							&:hover .overlay {
								opacity: 1;
								left: 0;
							}
						}
					}

					.plan-graphic {
						overflow:hidden;
						// flex: 2;
						width: 100%;
						// height: 400px;
						background-size: cover;
						background-position: center;
						background-repeat: no-repeat;
						// margin-right: 0;
						flex: auto;
						height: 350px;
						margin: 0 10px;
						flex-basis: calc(50% - 20px);
						margin-right: 0;

						p {
							margin: 0;
						}

						a {
							display: block;
						}
					}

					.dev-desc {
						// flex: 1;
						// align-self: flex-start;
						// ul {
						// 	padding-left: 15px;

						// 	li {
						// 		margin: 10px 0;
						// 		line-height: 1.75;
						// 	}
						// } 
						flex: auto;
						flex-basis: 100%;
						align-self: auto;
						margin: 0;
						ul {
							margin-top: 30px;
							padding-left: 15px;
								li {
									margin: 10px 0;
									line-height: 1.75;
								}
						} 

						a {
							color: $font;
							transition: all 0.3s;

							&:hover {
								color: $red;
							}
						}
					}
				}
				


				// &:hover {
				// 	border: 10px solid #F1F1F1;
				// }

			}
		}
	}
}

@media all and (max-width: 1059px) {
	.dev-page {

		.container {

			.dev-container {

				.dev {
					flex-basis: 100%;
				}
			}
		}
	}
}

@media all and (max-width: 960px) {
	.dev-page {

		.container {

			.dev-container {
				.dev {

					.dev-content {	

						a.link {
							// margin-left: 0;
							// flex: auto;
							// margin: 0 10px;
							// flex-basis: calc(50% - 20px);
							// margin-left: 0;

							.dev-map {
								// width: 100%;
								// height: 350px;
							}
						}

						.plan-graphic {
							// flex: auto;
							// height: 350px;
							// margin: 0 10px;
							// flex-basis: calc(50% - 20px);
							// margin-right: 0;
						}

						.dev-desc {
							// flex: auto;
							// flex-basis: 100%;
							// align-self: auto;
							// margin: 0;
							// ul {
							// 	margin-top: 30px;
							// 	padding-left: 15px;
							// } 
						}
					}
				}
			}
		}
	}
}

@media all and (max-width: 600px) {
	.dev-page {

		.container {

			.dev-container {
				.dev {

					.dev-content {	

						a.link {
							margin-left: 0;
							flex: auto;
	
							flex-basis: 100%;
							margin: 0;

							.dev-map {
								width: 100%;
								height: 350px;
							}
						}

						.plan-graphic {
							flex: auto;
							height: 350px;
							flex-basis: 100%;
							margin: 30px 0 0 0;
						}

						.dev-desc {
							flex: auto;
							flex-basis: 100%;
							align-self: auto;
							margin: 0;
							ul {
								margin-top: 30px;
								padding-left: 15px;
							} 
						}
					}
				}
			}
		}
	}
}