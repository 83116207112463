

.site-footer {
	width: 100vw;
	height: 90px;
	background: $red;

	.container {
		padding: 10px 30px;
		height: 100%;

		.site-info {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			// flex-direction: column;
			// margin: 0 auto;

			.address,
			.contact,
			.copy {
				margin: 0 5px;
				flex: 1 1 calc(33.333% - 10px);
				text-align: center;
				padding: 0 5px;
			}

			.address {
				margin-left: 0;
				flex: 2;
				border-right: 1px solid #fff;
				// background: mistyrose;
			}

			.address,
			.contact {
				// padding-right: 20px;
			}

			.contact {
				padding: 0;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				flex: 2;
				// background: goldenrod;

				.phone,
				.email {
					margin: 0 5px;
					flex: 1 1 calc(50% - 10px);
					padding: 0 10px;
				}

				.phone {
					margin-left: 0;
					border-right: 1px solid #fff;
					flex: 1;
					// padding-right: 10px;
				}

				.email {
					margin-right: 0;
					border-right: 1px solid #fff;
					flex: 2;
					// padding-left: 10px;
				}
			}

			.copy {
				// text-align: c;
				margin-right: 0;
				flex:1;
				// background: aqua;
			}
			
		}
	}
}

@include breakpoint(medium) {
	.site-footer {
		.container {
			.site-info {

				.address,
				.contact,
				.copy {
					// margin: 0 5px;
					flex: 1 1 100%;
					text-align: left;
				}

				.address {
					border-right: none;
				}

				.contact {
					justify-content: flex-start;

					.phone,
					.email {
						margin: 0 5px;
						flex: none;
						padding: 0 10px;
					}

					.phone {
						margin-left: 0;
						padding-left: 0;
					}

					.email {
						border-right: 0;
						padding-left: 0;
					}

				}


				.copy {
					margin-left: 0;
					text-align: left;
				}
			}
		}
	}
}

@include breakpoint(small) {
	.site-footer {
		height: auto;

		.container {
			padding: 20px 30px;

			.site-info {
				// display: block;

				// .address,
				// .contact {
				// 	margin-bottom: 10px;
				// }

				// .copy {
				// 	text-align: left;
				// }

				.address,
				.contact,
				.copy {
					// margin: 0 5px;
					flex: 1 1 100%;
					text-align: left;
						margin-bottom: 5px;
				}

				.address {
					border-right: none;
				}

				.contact {
					justify-content: flex-start;

					.phone,
					.email {
						margin: 0 5px;
						flex: none;
						padding: 0 10px;
					}

					.phone {
						margin-left: 0;
						padding-left: 0;
					}

					.email {
						border-right: 0;
						padding-left: 0;
					}

				}


				.copy {
					margin-left: 0;
					text-align: left;
				}
			}
		}
	}
}