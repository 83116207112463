html {
	font-size: 14px;
}

@include breakpoint(medium) {
	html {
		font-size: 12px;		
	}
}

@include breakpoint(small) {
	html {
		font-size: 11px;	
	}
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Montserrat', sans-serif;
	font-weight: 700;
	margin: 0;
	line-height: 1.1;
} 

p {
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	line-height: 1.75;
}

h1 {
	// font-size: 4.285714286rem;
	font-size: 3.9285714291rem;
	font-weight: 400;
}

h2 {
	// font-size: 4.285714286rem;
	font-size: 3.9285714291rem;
	font-weight: 400;
	color: $red;
}

h3 {
	font-size: 3rem;
	color: $red;
	font-weight: 400;
	margin: 20px 0 40px 0;
}

h4 {
	font-size: 2.428571429rem;
	font-weight: 400;
	color: $font;
}

h5 {
	font-size: 1.285714286rem;
	font-weight: 400;
	color: $red;
}

h6 {
	font-size: 1.714285714rem;
	font-weight: 400;
	color: $red;
}

p, li {
	font-size: 1.142857143rem;
	color: $font;
}

p.small {
	font-size: 1rem;
}

.testimonials p.small-title {
	font-size: 1.285714286rem;
	font-weight: 700;
	color: $red;
	letter-spacing: 1px;
}

p.xsmall {
	font-size: 0.857142857rem;
}

.main-navigation ul li a {
	font-family: 'Montserrat', sans-serif;
	font-weight: 400;
	color: $red;
	font-size: 0.928571429rem;
}

.site-footer {
	p {
		color: #fff;
		margin: 0;
		line-height: 1.3;
		font-size: 1rem;
	}
	a {
		color: #fff;
		text-decoration: none;
		font-size: 1rem;

		&:hover {
			text-decoration: underline;
		}
	}
}